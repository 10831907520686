import "./css/normalize.css";
import "./css/style.css";
import "./css/all.min.css";
import Header from './components/header';
import Footer from './components/footer';
import Spinner from './components/spinner';
import { images } from './components/importingScript';
import Services from "./components/Service1"
import About from "./components/About1"
import Contact from "./components/Contact1";
import FourBoxes from "./components/boxes";
import WhatsAppIcon from "./components/whatsapp";


function App() {
  return (
    <>
    <Header /> 
    <Spinner />
      <div className="hero">
      <div className="container-fluid px-0">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={images["./carousel-1.jpg"]} alt="Image" ></img>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-7 text-start">
                      <p className="fs-4 text-white animated slideInRight">Welcome to <strong>Spark Digital India</strong></p>
                      <h1 className="display-1 text-white mb-4 animated slideInRight">Unlock Your Business Growth</h1>
                      <a  className="btn btn-primary rounded-pill py-3 px-5 animated slideInRight" href='/contact'>Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
            <img 
  className="img-fluid w-100" 
  src='https://www.springboard.com/blog/wp-content/uploads/2022/05/how-to-become-a-digital-marketer.png' 
  alt="Image" 
/>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    {/* <div className="col-lg-7 text-start">
                      <p className="fs-4 text-white animated slideInRight">Welcome  <strong>Spark Digital India</strong></p>
                      <h1 className="display-1 text-white mb-4 animated slideInRight">We Support You to grow your business</h1>
                      <a href className="btn btn-primary rounded-pill py-3 px-5 animated slideInRight">Explore More</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      </div>
     
      {/* === Start Features === */}
      <FourBoxes/>
      <About/>
      <Services/>
      <Contact/>
      {/* === End Features === */}
      <Footer />
      
    </>
  );
}

export default App;