import React, { useState } from 'react';
import './b.css';

const Contact1 = () => {
  const [selectedService, setSelectedService] = useState("");

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  return (
    <div className="contact-container">
      <div className="contact-form">
        <h2>Enquire Now</h2>
        <form>
         
          <input type="text" placeholder="Name" required />
          <input type="email" placeholder="Email" required />
          <input type="tel" placeholder="Phone" />
          
          <select id="service" value={selectedService} onChange={handleServiceChange}>
            <option value="" disabled>--Select a Service--</option>
            <option value="consultation">Whatsapp SMS</option>
            <option value="support">Bulk SMS Service</option>
            <option value="feedback"> Voice SMS Service</option>
            <option value="consultation">Bulk Email Service</option>
            <option value="support">Lead Generation Service</option>
            <option value="feedback">Web Designing Service</option>
            {/* Add more options as needed */}
          </select>
          <textarea rows="5" placeholder="Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
      <div className="map">
        <iframe
          title="Google Maps"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bangalore%20-%20560097%20,%20karnataka+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact1;



