import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <nav className="container navbar navbar-expand-lg">
        <div className="bg-white container-fluid">
          <a href="index.html" className="logo navbar-brand d-lg-">
            <img src="https://www.sparkdigitalindia.com/img/logo.png" style={{ width: '100px' }} alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav main-links ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/home">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services">Services</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#projects">Projects</Link>
              </li> */}
              {/* <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Pages
                </Link>
                <ul className="dropdown-menu py-2 px-2 bg-light">
                  <li><a className="dropdown-item pb-1" href='#features'>Features</a></li>
                  <li><a className="dropdown-item pb-1" href='#ourteam'>Our Team</a></li>
                  <li><a className="dropdown-item pb-1" href='#testimonial'>Testimonial</a></li>
                  <li><a className="dropdown-item pb-1" href='#quotation'>Quotation</a></li>
                  <li><Link className="dropdown-item pb-1" to='/pagenotfound'>404 Page</Link></li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact</Link>
              </li>
            </ul>
            <button type="button" href='/contact' className="ms-auto d-none d-lg-block btn btn-primary rounded-pill py-2 px-3">Call Us</button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
