import Header from './header'
import Footer from './footer'
import Eachpart from "./eachpart";

// [Script] => Import all imgs from assets From importingScript file
import { images } from './importingScript';

const About = ({page}) => {
  return (
    <>
     

      {/* About Section */}
      <div pageName="About" className="container-xxl about mb-5" style={{marginTop: '6rem'}}>
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6">
              {/* <div className="h-100 d-flex align-items-center justify-content-center" style={{minHeight: '300px'}}>
                <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                  <span />
                </button>
              </div> */}
            </div>
            <div className="col-lg-6 pt-lg-5 wow fadeIn" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeIn'}}>
              <div className="bg-white rounded-top p-5 mt-lg-5">
                <p className="fs-5 fw-medium text-primary">Welcome To Spark digital India</p>
                <h1 className="display-6 mb-4">The Best Marketing Agency to Improve Your Business</h1>
                <p className="mb-4">Spark Digital India Bulk SMS loacted in banglore is India's most reliable online bulk sms website providing bulk sms through websms, bulk sms software & bulk sms Http API. We make it very easy for you to send bulk SMS to your customers and employees from the websms or APIcode. If you are looking for a full service bulk sms website / internet sms / online sms provider, our web sms portal is considered best in its class.</p>
                {/* <div className="row g-5 pt-2 mb-5">
                  <div className="col-sm-6">
                    <img className="img-fluid mb-4" src={images["./icon/icon-1.png"]}  alt="icon" />
                    <h5 className="mb-3">Managed Services</h5>
                    <span>Clita erat ipsum et lorem et sit sed stet lorem</span>
                  </div>
                  <div className="col-sm-6">
                    <img className="img-fluid mb-4" src={images["./icon/icon-2.png"]}  alt="icon" />
                    <h5 className="mb-3">Dedicated Experts</h5>
                    <span>Clita erat ipsum et lorem et sit sed stet lorem</span>
                  </div>
                </div> */}
                <a className="btn btn-primary rounded-pill py-3 px-5" href='/contact'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default About;